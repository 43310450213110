body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    color: #dcdcdc; /* Light grey text */
    background: linear-gradient(135deg, #1e3a5f, #1e3a8e); /* Blue gradient background */
}

header {
    background: linear-gradient(135deg, #1e3a5f, #1e3a8e); /* Blue gradient background */
    color: white;
    text-align: center;
    padding: 1rem 0;
}

nav {
    background-color: #1e3a5f; /* Dark blue background */
    text-align: center;
    padding: 1rem 0;
}

nav a {
    color: #4a90e2; /* Lighter blue for links */
    text-decoration: none;
    margin: 0 1rem;
}

nav a:hover {
    color: #00ff00; /* Bright green on hover */
}

.container {
    width: 80%;
    margin: 2rem auto;
    overflow: hidden;
    padding: 2rem;
    background-color: #2b2b2b; /* Dark grey background */
    border-radius: 10px;
    color: #dcdcdc; /* Light grey text */
}

h1 {
    color: #4a90e2; /* Lighter blue */
    text-align: center;
}    

h2 {
    color: #4a90e2; /* Lighter blue */
}

.section {
    margin-bottom: 2rem;
}

footer {
    background: linear-gradient(135deg, #1e3a5f, #1e3a8e); /* Same as body background */
    color: white;
    text-align: center;
    padding: 1rem 0;
    position: fixed;
    width: 100%;
    bottom: 0;
}

a {
    color: #4a90e2; /* Lighter blue for links */
}

a:hover {
    color: #00ff00; /* Bright green on hover */
}
